import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
import './i18n';
import { ChakraProvider } from '@chakra-ui/react';
import App from './App';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { keycloakObject } from './keycloak';
import theme from './theme';
import { createClient, Provider as UrqlProvider, cacheExchange, fetchExchange, Exchange } from 'urql';
import { authExchange } from '@urql/exchange-auth';
import { retryExchange } from '@urql/exchange-retry';

import * as Sentry from '@sentry/react';
import { User } from '@sentry/react';
import { brandParameters } from './appBrand';

const environment = import.meta.env.VITE_SENTRY_ENV ?? 'production';
const sentryDisabled = import.meta.env.VITE_SENTRY_DISABLED
    ? import.meta.env.VITE_SENTRY_DISABLED === 'yes'
    : !brandParameters.sentry.enabled;

const showTokenDebugging = false;

if (!sentryDisabled) {
    Sentry.init({
        enabled: !sentryDisabled,
        dsn: brandParameters.sentry.dsn,
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect: React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
            Sentry.replayIntegration({
                networkDetailAllowUrls: [brandParameters.backend.serverUrl, 'http://localhost:3000/graphql'],
            }),
        ],
        environment,
        tracePropagationTargets: brandParameters.sentry.tracingOrigins,
        debug: environment === 'development',

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: environment === 'development' ? 1.0 : 0.1,
        replaysSessionSampleRate: environment === 'development' ? 1.0 : 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}
export const KEYCLOAK_KEY_TOKEN = 'token';
export const KEYCLOAK_KEY_REFRESH_TOKEN = 'refreshToken';
export const KEYCLOAK_KEY_EXPIRATION = 'expiration';

// None of these options have to be added, these are the default values.
const options = {
    initialDelayMs: 1000,
    maxDelayMs: 15000,
    randomDelay: true,
    maxNumberAttempts: 2,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    retryIf: (err: any) => err && err.networkError,
};

const eventLogger = (event: unknown, error: unknown) => {
    if (error) {
        if (showTokenDebugging) console.log('onKeycloakEvent', event, error);
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const tokenLogger = (tokens: any) => {
    if (tokens?.token) {
        if (showTokenDebugging) console.log(`TOKENDBG: tokenLogger - Got token`);
        localStorage.setItem(KEYCLOAK_KEY_TOKEN, tokens.token);
        localStorage.setItem(KEYCLOAK_KEY_REFRESH_TOKEN, tokens.refreshToken);
        localStorage.setItem(
            KEYCLOAK_KEY_EXPIRATION,
            keycloakObject.tokenParsed?.exp ? keycloakObject.tokenParsed?.exp.toString() : '',
        );
        if (keycloakObject.tokenParsed?.sub) {
            const sub = keycloakObject.tokenParsed?.sub;
            const advisorId = sub.match(/f:.*:(.*)/);
            if (advisorId) {
                const email = keycloakObject.tokenParsed?.email;
                const username = keycloakObject.tokenParsed?.name;
                const sentryUser: User = { id: advisorId[1] };
                if (username) {
                    sentryUser.username = username;
                }
                if (email) {
                    sentryUser.email = email;
                }
                Sentry.setUser(sentryUser);
            }
        }
        const token = localStorage.getItem(KEYCLOAK_KEY_TOKEN);
        const refreshToken = localStorage.getItem(KEYCLOAK_KEY_REFRESH_TOKEN);
        const tokenExpiration = localStorage.getItem(KEYCLOAK_KEY_EXPIRATION);

        if (showTokenDebugging) {
            console.log(`TOKENDBG: tokenLogger - token: ${token}`);
            console.log(`TOKENDBG: tokenLogger - refreshToken: ${refreshToken}`);
            console.log(`TOKENDBG: tokenLogger - tokenExpiration: ${tokenExpiration}`);
        }
    } else {
        if (showTokenDebugging) console.log(`TOKENDBG: tokenLogger - No token?`);
    }
};

// Markus:
// Recording is deprecated as we are using Session Replay for this.
//
// export const recordExchange: Exchange = ({ forward }) => {
//     const nameForOperation = (op: Operation): string => {
//         let operationName = op.key.toString();
//         if (op.query.definitions.length > 0 && op.query.definitions[0].kind === Kind.OPERATION_DEFINITION) {
//             const firstDefinition = op.query.definitions[0] as unknown as OperationDefinitionNode;
//             if (firstDefinition && firstDefinition.name?.value) {
//                 operationName = firstDefinition.name?.value;
//             }
//         }
//         return operationName;
//     };
//     const activeSpan = Sentry.getActiveSpan();
//     return Sentry.startSpanManual({ op: 'graphql.query', name: 'graphql' }, (span) => {
//         // eslint-disable-next-line @typescript-eslint/no-explicit-any
//         return (ops$: Source<Operation<any, AnyVariables>>) =>
//             pipe(
//                 ops$,
//                 // eslint-disable-next-line no-console
//                 tap((op) => {
//                     const operationName = nameForOperation(op);
//                     if (showTokenDebugging) console.log(`[Exchange debug]: Incoming operation: ${operationName}`, op);
//                     if (span) {
//                         span.setAttribute('operation', operationName);
//                         span.updateName(`GraphQL:${operationName}`);

//                         if (activeSpan) {
//                             op.context.fetchOptions = {
//                                 headers: { 'sentry-trace': spanToTraceHeader(activeSpan) },
//                             };
//                         }
//                     }
//                 }),
//                 forward,
//                 tap((result) => {
//                     const operationName = nameForOperation(result.operation);
//                     if (showTokenDebugging)
//                         console.log(`[Exchange debug]: Completed operation: ${operationName}`, result);
//                     if (span) {
//                         if (result.error) {
//                             span.setStatus('failed');
//                             span.setAttributes({
//                                 error: result.error.message,
//                                 errorDetails: result.error.stack,
//                             });
//                         } else {
//                             span.setStatus('success');
//                         }
//                         console.warn(`[SPAN] details before end : ${JSON.stringify(spanToJSON(span))}`);
//                         span.end();
//                         console.warn(`[SPAN] details after end: ${JSON.stringify(spanToJSON(span))}`);
//                     } else {
//                         console.error(`[No SPAN]: Completed operation: ${operationName}`, result);
//                     }
//                 }),
//             );
//     });
// };

const auth = authExchange(async (utilities) => {
    return {
        addAuthToOperation(operation) {
            const token = localStorage.getItem(KEYCLOAK_KEY_TOKEN);
            return token
                ? utilities.appendHeaders(operation, {
                      Authorization: `Bearer ${token}`,
                  })
                : operation;
        },
        willAuthError() {
            const token = localStorage.getItem(KEYCLOAK_KEY_TOKEN);
            const tokenExpiration = localStorage.getItem(KEYCLOAK_KEY_EXPIRATION);

            if (!token) {
                if (showTokenDebugging) console.log(`TOKENDBG: willAuthError - true - no token`);
                return true;
            }
            if (keycloakObject.isTokenExpired()) {
                if (showTokenDebugging) console.log(`TOKENDBG: willAuthError - true - keycloak token expired`);
                return true;
            }
            if (tokenExpiration) {
                const expiration = parseInt(tokenExpiration, 10);
                if (expiration < Date.now() / 1000) {
                    if (showTokenDebugging) console.log(`TOKENDBG: willAuthError - true - token expired`);
                    return true;
                }
            }
            if (showTokenDebugging) console.log(`TOKENDBG: willAuthError - false`);
            return false;
        },
        didAuthError(error) {
            return error.graphQLErrors.some((e) => e.extensions?.code === 'UNAUTHORIZED');
        },
        async refreshAuth() {
            const token = localStorage.getItem(KEYCLOAK_KEY_TOKEN);

            if (token) {
                try {
                    const tokenUpdated = await keycloakObject.updateToken(5);
                    if (tokenUpdated) {
                        if (showTokenDebugging) console.log(`TOKENDBG: getAuth - got updated token`);
                        if (keycloakObject.token && keycloakObject.refreshToken) {
                            if (showTokenDebugging) console.log(`TOKENDBG: getAuth - store new token`);
                            localStorage.setItem(KEYCLOAK_KEY_TOKEN, keycloakObject.token);
                            localStorage.setItem(KEYCLOAK_KEY_REFRESH_TOKEN, keycloakObject.refreshToken);
                            localStorage.setItem(
                                KEYCLOAK_KEY_EXPIRATION,
                                keycloakObject.tokenParsed?.exp ? keycloakObject.tokenParsed?.exp.toString() : '',
                            );
                            return;
                        }
                    }
                } catch (error) {
                    console.log(`TOKENDBG: getAuth - catched ${error} = logging out`);
                }
            }
            keycloakObject.clearToken();
            localStorage.removeItem(KEYCLOAK_KEY_TOKEN);
            localStorage.removeItem(KEYCLOAK_KEY_REFRESH_TOKEN);
            localStorage.removeItem(KEYCLOAK_KEY_EXPIRATION);
            keycloakObject.login();
        },
    };
});

const client = createClient({
    url: import.meta.env.VITE_SERVER_URL ? import.meta.env.VITE_SERVER_URL : brandParameters.backend.serverUrl,
    exchanges: [cacheExchange, auth as Exchange, retryExchange(options) as Exchange, fetchExchange],
    requestPolicy: 'network-only',
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <ReactKeycloakProvider
        initOptions={{ onLoad: 'login-required', checkLoginIframe: false }}
        authClient={keycloakObject}
        onEvent={eventLogger}
        onTokens={tokenLogger}
    >
        <ChakraProvider theme={theme}>
            <UrqlProvider value={client}>
                <BrowserRouter>
                    <React.StrictMode>
                        <App />
                    </React.StrictMode>
                </BrowserRouter>
            </UrqlProvider>
        </ChakraProvider>
    </ReactKeycloakProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
