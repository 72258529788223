import React, { FunctionComponent } from 'react';
import { SimpleGrid } from '@chakra-ui/react';
import { ContentContainer } from '../../components/ContentContainer';
import { config } from '../../content_builder_config/dashboard';
import { ContentBuilder } from '../../components/ContentBuilder';
import { useDashboardStatsQuery } from '../../generated/types';
import LoadingIndicator from '../../components/LoadingIndicator';
import ErrorBox from '../../components/ErrorBox';
import { isCodie } from '../../appBrand';
import { useKeycloak } from '@react-keycloak/web';
import { checkScope, getUser } from '../../keycloak';

const infoUrl = isCodie
    ? 'https://hilfe.dashboard.app.fp-finanzpartner.de/sl-SI/kb/articles/welche-vorteile-bieten-mir-die-fp-360-app-berater-dashboard-als-berater-in'
    : undefined;

export const Dashboard: FunctionComponent = () => {
    const { keycloak } = useKeycloak();
    const user = getUser(keycloak);

    const appInviteStatsScopes = ['walt_admin', 'walt_supervisor', 'walt_fp_supervisor'];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [resultStatsQuery, reexecuteStatsQuery] = useDashboardStatsQuery();
    const { data, fetching, error } = resultStatsQuery;

    if (fetching) return <LoadingIndicator />;
    if (error) return <ErrorBox message={error.message} />;

    let extendedStats = {};
    if (checkScope(user?.roles, appInviteStatsScopes)) {
        const autoInviteInvitedCustomers = data?.dashboardStats?.autoInviteInvitedCustomers ?? 0;
        const autoInviteInstalledCustomers = data?.dashboardStats?.autoInviteInstalledCustomers ?? 0;
        const autoInviteSignedCustomers = data?.dashboardStats?.autoInviteSignedCustomers ?? 0;
        const autoInviteAppInUseCustomers = data?.dashboardStats?.autoInviteAppInUseCustomers ?? 0;

        if (autoInviteInvitedCustomers > 0) {
            extendedStats = {
                autoInviteInvitedCustomers,
                autoInviteInstalledCustomers: `${data?.dashboardStats?.autoInviteInstalledCustomers} / ${((100 / autoInviteInvitedCustomers) * autoInviteInstalledCustomers).toFixed(1).replace('.', ',')} %`,
                autoInviteSignedCustomers: `${data?.dashboardStats?.autoInviteSignedCustomers} / ${((100 / autoInviteInvitedCustomers) * autoInviteSignedCustomers).toFixed(1).replace('.', ',')} %`,
                autoInviteAppInUseCustomers: `${data?.dashboardStats?.autoInviteAppInUseCustomers} / ${((100 / autoInviteInvitedCustomers) * autoInviteAppInUseCustomers).toFixed(1).replace('.', ',')} %`,
            };
        }
    }

    return (
        <ContentContainer headline="dashboard" description="dashboardDescription" infoUrl={infoUrl}>
            <SimpleGrid columns={{ base: 1, md: 4 }} gap={{ base: '5', md: '6' }}>
                <ContentBuilder
                    config={config.stats}
                    data={{
                        invitedCustomers:
                            (data?.dashboardStats?.invitedCustomers ?? 0) +
                            (data?.dashboardStats?.appinuseCustomers ?? 0) +
                            (data?.dashboardStats?.acceptedCustomers ?? 0),
                        acceptedCustomers:
                            (data?.dashboardStats?.appinuseCustomers ?? 0) +
                            (data?.dashboardStats?.acceptedCustomers ?? 0),
                        appinuseCustomers: data?.dashboardStats?.appinuseCustomers,
                        unreadCommunications: data?.dashboardStats?.unreadCommunications,
                        ...extendedStats,
                    }}
                    methods={{}}
                />
            </SimpleGrid>
        </ContentContainer>
    );
};
