import { isCodie } from '../appBrand';

const codie: { [key: string]: string } = {
    activeCustomers: 'Aktive FP 360° Nutzer',
    dashboardDescription: 'Hier erhältst du einen Überblick über deine FP 360° Kunden und offenen Nachrichten.',
    inviteCustomersDescription: 'Über diese Liste kannst du deine Kunden für die Nutzung der FP 360° App einladen.',
    appCustomersHeadline: 'FP 360° Nutzer',
    appCustomersDescription:
        'In dieser Liste verwaltest du die aktiven FP 360° Nutzer. Du kannst nach Namen, Kundennummer und Berater/in suchen.',
    appCustomers: 'FP 360° Nutzer',
    inviteMessage: `Sehr geehrter *Anrede* *Nachname*,

wir laden Sie herzlich ein Ihre Kontostände und Verträge über die FP Finanzpartner App abzurufen und zu verwalten - dadurch vereinfachen Sie ihre Verwaltung aller Finanzen. Darüber hinaus ermöglichen wir Ihnen eine schnelle und einfache Kommunikation über die App mit mir als Ihren persönlichen Berater.

Besuchen Sie den App Store oder Google Play Store und laden sich die App "FP Finanzapp“ herunter. Zugang erhalten Sie über den einmaligen Einladungscode: 3490194921

Bei weiteren Fragen stehe ich Ihnen jederzeit zur Verfügung.

Mit freundlichen Grüßen,
*Signatur*
 `,
    advisorDescription:
        'Hier kannst du deine Kontaktdaten einsehen, die in der FP 360° App für deine Kunden angezeigt werden. Die Daten werden aus CODie ausgelesen. Alle Datenänderungen müssen direkt in CODie durchgeführt werden.',
    loginHeadline: 'FP 360° App Dashboard',
    appData: 'FP 360° App-Daten',
    appAcceptedCustomers: 'App Vertrag angenommen',
};

const finAffin: { [key: string]: string } = {
    activeCustomers: 'Aktive Nutzer',
    dashboardDescription: 'Hier erhältst du einen Überblick über deine Kunden und offenen Nachrichten.',
    inviteCustomersDescription: 'Über diese Liste kannst du deine Kunden für die Nutzung der App einladen.',
    appCustomersHeadline: 'Nutzer',
    appCustomersDescription:
        'In dieser Liste verwaltest du die aktiven Nutzer. Du kannst nach Namen, Kundennummer und Berater/in suchen.',
    appCustomers: 'Nutzer',
    inviteMessage: `Sehr geehrter *Anrede* *Nachname*,

wir laden Sie herzlich ein Ihre Kontostände und Verträge über die FinAffin App abzurufen und zu verwalten - dadurch vereinfachen Sie ihre Verwaltung aller Finanzen. Darüber hinaus ermöglichen wir Ihnen eine schnelle und einfache Kommunikation über die App mit mir als Ihren persönlichen Berater.

Besuchen Sie den App Store oder Google Play Store und laden sich die App "FinAffin" herunter. Zugang erhalten Sie über den einmaligen Einladungscode: 3490194921

Bei weiteren Fragen stehe ich Ihnen jederzeit zur Verfügung.

Mit freundlichen Grüßen,
*Signatur*
 `,
    advisorDescription:
        'Hier kannst du deine Kontaktdaten einsehen, die in der FinAffin App für deine Kunden angezeigt werden.',
    loginHeadline: 'FinAffin App Dashboard',
    appData: 'App-Daten',
    appAcceptedCustomers: 'App Vertrag angenommen',
};

const getText = (label: string) => {
    if (isCodie) return codie[`${label}`];
    return finAffin[`${label}`];
};

export const basic = {
    actions: 'Aktionen',
    dashboard: 'Dashboard',
    customers: 'Kunden',
    contacts: 'Kontakte',
    messages: 'Nachrichten',
    logout: 'Abmelden',
    search: 'Suche',
    nameEmail: 'Name, E-Mail',
    nameInvitation: 'Name, Status',
    requestFrom: 'Anfrage vom',
    customerId: 'Kunden-ID',
    title: 'Betreff',
    message: 'Nachricht',
    openRequests: 'Offene Anfragen',
    appAcceptedCustomers: getText('appAcceptedCustomers'),
    activeCustomers: getText('activeCustomers'),
    dashboardDescription: getText('dashboardDescription'),
    openMessagesCount: 'Offene Nachrichten',
    inviteCustomersHeadline: 'Kontakte einladen',
    inviteCustomersDescription: getText('inviteCustomersDescription'),
    appCustomersHeadline: getText('appCustomersHeadline'),
    appCustomersDescription: getText('appCustomersDescription'),
    openMessages: 'Offene Nachrichten',
    countContracts: 'Anzahl Verträge',
    addCustomer: 'Einladen',
    edit: 'Bearbeiten',
    email: 'E-Mail',
    homePhone: 'Telefonnummer (Privat)',
    mobilePhone: 'Telefonnummer (Mobil)',
    birthday: 'Geburtstag',
    personalInformation: 'Persönliche Informationen',
    contracts: 'Verträge',
    codieContracts: 'CODie-Verträge',
    records: 'Einträge',
    page: 'Seite',
    of: 'von',
    firstName: 'Vorname',
    lastName: 'Nachname',
    date: 'Datum',
    customerIdentifier: 'Kundennummer',
    name: 'Name',
    impulses: 'Impulse',
    notes: 'Notizen',
    noRecords: 'Keine Einträge',
    mr: 'Herr',
    mrs: 'Frau',
    divers: 'Divers',
    salutation: 'Anrede',
    select: 'Auswählen',
    invite: 'Einladen',
    videoident: 'Video Ident',
    reinvite: 'Erneut einladen',
    inviteCustomer: 'Kunde einladen',
    invalidFieldValue: 'Bitte fülle dieses Feld korrekt aus.',
    invalidEmail: 'Ungültige E-Mail-Adresse',
    invalidMobilePhone: 'Ungültige Telefonnummer',
    cancel: 'Abbrechen',
    ProductName: 'Produkt',
    recurrence: 'Zahlungsintervall',
    productName: 'Produkt',
    accounts: 'Konten',
    assets: 'Vermögen',
    codieAssets: 'CODie-Vermögen',
    balanceDate: 'Saldo Datum',
    balance: 'Saldo',
    amount: 'Betrag',
    displayName: 'Name',
    ContractNumber: 'Vertragsnummer',
    CompanyName: 'Gesellschaft',
    StartDate: 'Anfangsdatum',
    EndDate: 'Enddatum',
    appCustomers: getText('appCustomers'),
    inviteCustomers: 'Einladen',
    salutationLetter: 'Briefanrede',
    Uninvited: 'Nicht Eingeladen',
    Invited: 'Eingeladen',
    Accepted: 'Angenommen',
    Expired: 'Abgelaufen',
    Appinuse: 'App in Benutzung',
    PhoneMissing: 'Mobilnummer fehlt',
    EmailMissing: 'E-Mail fehlt',
    PhoneInvalid: 'Mobilnummer ungültig',
    EmailInvalid: 'E-Mail ungültig',
    SalutationLetterMissing: 'Briefanrede fehlt',
    EverythingFine: 'OK',
    dataErrors: 'Datenstatus',
    invitationState: 'Einladungsstatus',
    advisorFullName: 'Berater/in',
    invitationStateBadge: 'Einladungsstatus',
    dataErrorBadge: 'Datenstatus',
    inviteMessage: getText('inviteMessage'),
    unknownError: 'Unbekannter Fehler',
    unkownNetworkError: 'Verbindungsfehler',
    communicationDescription: 'Hier erhältst du einen Überblick über die Kommunikation mit deinen Kunden.',
    advisor: 'Berater',
    advisorDescription: getText('advisorDescription'),
    newCustomers: 'Neue Kunden',
    saveChanges: 'Änderungen speichern',
    login: 'Anmelden',
    loginHeadline: getText('loginHeadline'),
    All: 'Alle',
    invitationSent: 'Einladung wurde erfolgreich versendet!',
    messageSent: 'Nachricht wurde erfolgreich versendet!',
    undoInviteSuccess: 'Einladung wurde erfolgreich zurückgezogen!',
    Back: 'Zurück',
    subject: 'Betreff',
    beginLetter: 'Begrüßungstext',
    endLetter: 'Grußworte und Signatur',
    linkPart: 'Einladungslink',
    advisors: 'Berater',
    listAdvisors: 'Übersicht',
    more: 'Mehr',
    advisorsDescription: 'Hier erhältst du einen Überblick über die für dich einsehbaren Berater.',
    invitedCustomers: 'Eingeladene Kontakte',
    NoData: 'Keine Daten empfangen',
    Error: 'Fehler',
    displayAddress: 'Adresse',
    faxPhone: 'Faxnummer',
    socialInsuranceNumber: 'Sozialversicherungsnummer',
    taxId: 'Steuer-ID',
    fullName: 'Name',
    customer: 'Kunde',
    customerDescription: 'Hier kannst du die Kundendaten einsehen und bearbeiten.',
    sender: 'Sender',
    User: 'Kunde',
    Advisor: 'Berater',
    Send: 'Senden',
    Close: 'Schließen',
    Service: 'Zentrale',
    userRead: 'Von Kunden gelesen',
    Monthly: 'Monatlich',
    BiMonthly: '2-Monatlich',
    Annually: 'Jährlich',
    Quarterly: 'Vierteljährlich',
    OneTime: 'Einmalig',
    SemiAnnually: 'Halbjährlich',
    Show: 'Zeige',
    appSettings: 'App-Einstellungen',
    invalidJSON: 'Fehlerhafte JSON-Struktur',
    entitlement: 'Einstellungen',
    entitlementSaved: 'Einstellungen gespeichert',
    attachments: 'Anhänge',
    searching: 'suchen',
    messageCount: 'Anzahl der Nachrichten',
    advisorRead: '', // empty column header
    unread: 'Ungelesen',
    read: 'Gelesen',
    customerLabel: 'Kunde',
    company: 'Firma',
    companyFaxPhone: 'Faxnummer',
    companyEmail: 'E-Mail',
    companyHomePhone: 'Telefonnummer (Privat)',
    companyMobilePhone: 'Telefonnummer (Mobil)',
    newMessage: 'Neue Nachricht',
    undoInvite: 'Ausladen',
    save: 'Speichern',
    dragAndDropFiles: 'Markiere und ziehe die zu sendenden Dateien in diesen Bereich.',
    dragAndDropAllowed: 'Folgende Dateitypen sind erlaubt: {{suffix}}',
    invitationPanelText: 'Einladungstext',
    process: 'Ablauf',
    hiddenTableElements: 'Ausgeblendet ({{count}})',
    appData: getText('appData'),
    accountName: 'Bezeichnung',
    bankName: 'Bank',
    balanceWithDate: 'Aktuelles Saldo (Datum)',
    metaDataNew:
        'Es liegen Kundendaten vom {{newDate}} vor.\n\nDurch die Anzeige der Daten (Salden von Konten & Offline-Konten, Höhe & Name von selbstangelegten Budgets), übernimmst du sämtliche Pflichten im Sinne deines Maklermandates und musst die angezeigten Daten in deiner Arbeit berücksichtigen.\n\nDer Zugriff wird protokolliert und die Daten einmalig freigegeben. Sollten sich nach der Anzeige weitere Änderungen an den Kundendaten ergeben, kannst du diesen Prozess jederzeit wiederholen.\n\nMöchtest du dir die Daten anzeigen lassen?',
    metaDataUpdate:
        'Es liegen neuere Kundendaten vom {{newDate}} vor.\n\nDurch die Anzeige der Daten (Salden von Konten & Offline-Konten, Höhe & Name von selbstangelegten Budgets), übernimmst du sämtliche Pflichten im Sinne deines Maklermandates und musst die angezeigten Daten in deiner Arbeit berücksichtigen.\n\nDer Zugriff wird protokolliert und die Daten einmalig freigegeben. Sollten sich nach der Anzeige weitere Änderungen an den Kundendaten ergeben, kannst du diesen Prozess jederzeit wiederholen.\n\nMöchtest du dir die Daten anzeigen lassen?',
    metaDataRequest:
        'Du kannst eine Anfrage an deinen Kunden stellen und ihn um die Übertragung der aktuellen App-Daten (Salden von Konten & Offline-Konten, Höhe & Name von selbstangelegten Budgets) bitten.',
    metaDataRequestPending:
        'Du hast am {{lastUserRequestedDate}} eine Anfrage an deinen Kunden gestellt und ihn um die Übertragung der aktuellen App-Daten (Salden von Konten & Offline-Konten, Höhe & Name von selbstangelegten Budgets) gebeten. Die Antwort steht noch aus.',
    metaDataRequestDenied:
        'Du hast am {{lastUserRequestedDate}} eine Anfrage an deinen Kunden gestellt und ihn um die Übertragung der aktuellen App-Daten (Salden von Konten & Offline-Konten, Höhe & Name von selbstangelegten Budgets) gebeten. Dein Kunde hat abgelehnt. Du kannst eine neue erneute Anfrage stellen.',

    metaDataCurrent: 'Daten vom {{date}}.',
    showNewData: 'Neue Daten anzeigen',
    requestNewData: 'Neue Daten anfragen',
    importantNotice: 'Wichtiger Hinweis',
    statusChanged: 'Status geändert',
    Done: 'Fertig',
    validationErrorRequired: 'Dieses Feld darf nicht leer sein.',
    validationErrorNumberMin: 'Dieses Feld muss mindestens den Wert {{value}} haben.',
    validationErrorNumberMax: 'Dieses Feld darf maximal den Wert {{value}} haben.',
    validationErrorNumberInteger: 'Dieses Feld darf keine Kommastelle enthalten',
    InProgress: 'In Arbeit',
    Ignored: 'Ignoriert',
    Unhandled: 'Unbearbeitet',
    category: 'Kategorie',
    budgetCategory: 'Budget-Kategorie',
    budgetRecurrence: 'Budget-Intervall',
    contractName: 'Bezeichnung',
    Help: 'Hilfe',
    lastBalance: 'Letzter Stand',
    lastBalanceWithDate: 'Letzter Stand (Datum)',
    currentBalanceDynamic: 'Höhe des Budgets (vom {{date}})',
    lastBalanceDynamic: 'Höhe des Budgets (vom {{date}})',
    changeInPercent: 'Veränderung in %',
    lastAppUsage: 'Letzte Verwendung',
    linkedContract: 'Verknüpfter Vertrag',
    accountId: 'Kontonummer',
    policy_number: 'Versicherungsnummer',
    contract_number: 'Vertragsnummer',
    costumer_number: 'Kundennummer',
    customer_number: 'Kundennummer',
    tax_id_number: 'Steueridentifikationsnummer',
    tax_number: 'Steuernummer',
    license_plate: 'Kennzeichen',
    insurance_holder: 'Versicherungsnehmer',
    provider: 'Vertragspartner',
    interest_period: 'Zinsperiode',
    interest_rate: 'Zinssatz',
    isin: 'ISIN',
    wkn: 'WKN',
    wpk: 'WPK',
    invoice_number: 'Rechnungsnummer',
    billing_date: 'Rechnungsdatum',
    billing_period: 'Abrechnungszeitraum',
    contractType: 'Erstellt',
    Manual: 'Manuell',
    Automatic: 'Automatisch',
    additionalDetails: 'Zusätzliche Informationen',
    lastTransaction: 'Letzter Umsatz',
    transferReason: 'Verwendungszweck',
    inactiveTableElements: 'Stillgelegt ({{count}})',
    saveSensibleUser: 'Sensibler User speichern',
    contractCount: 'Anzahl FP Verträge',
    autoInviteInvitedCustomers: 'automatische Einladungen',
    autoInviteInstalledCustomers: 'Installationen durch a.E.',
    autoInviteSignedCustomers: 'Akzeptiert durch a.E.',
    autoInviteAppInUseCustomers: 'App in Benutzung durch a.E.',
    searchCustomers: 'Suchen',
    InsuredPerson: 'Versicherte Person',
    ContractHolderPerson: 'Vertragsinhaber',
};
